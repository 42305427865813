
.sidebar_profile_menu{
    display: flex;
    flex-direction: column;
    box-shadow: 6px 3px 5px 0 rgba(155, 162, 167, 0.2);
    padding-right: 10px;
    width: 14vw;
}
.profile_box_container{
    background: #ffffff;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dfe2e4;
    border-radius: 10px;
}
.name_ptofile_box{
    font-weight: bold;
    margin-bottom: .5em;
}
.btn-sidebar-menu{
    display: flex;
    justify-content: space-between;
    padding: 1em;
    font-weight: bold;
    border-radius: 10px;
}
.btn-sidebar-menu:hover{
    cursor: pointer;
    border-radius: 10px;
    background: #e9edf0;
}
.active_path_sidebar{
    background: #e9edf0;
}
.circular_profile_img{
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 200px;
    /* height: 30%; */
}
@media (max-width: 400px) {
    .sidebar_profile_menu{
        display: none;
    }
}