.title_table_invoices{
    background: #fff;
    /* display: flex; */
    padding: 1em;
    margin-top: 1em;
    /* text-align: center; */
    border-radius: 6px;
    text-align: center;
    font-weight: 700;
    font-size: .8rem;
    /* justify-content: space-between; */
}
.title_table_invoices_content{
    background: #fff;
    /* display: flex; */
    /* padding: 1em;
    margin-top: 1em; */
    /* text-align: center; */
    border-radius: 6px;
    text-align: center;
    font-weight: 500;
    font-size: .8rem;
}
.free_word_invoice{
    color: #77c8ae;
}
.invoice_line{
    padding-top: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d3dce2;
}
.invoice{
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
}
.free_word{
    color:#00b873;
}