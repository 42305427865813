.submenu_sidebar{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    display: flex;
    padding: .55em 0;
    text-decoration: none;
}
.submenu_sidebar:hover{
    cursor: pointer;
}
.brands_sidebar{
    padding-bottom: 30px;
}
.brand_subtheme:hover{
    cursor: pointer;
}
.bi-check{
    font-size: 22px;
    color: #00b873;
}
.bi-circle{
    color:#9ba2a7;
    font-size: 20px;
}
.hide-item{
    display: none;
}