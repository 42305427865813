.my_account_container{
    display: flex;
    /* height: 100vh; */
    
}
.content_my_account{
    width: 78vw;
    padding-left: 1.5em;
}
.content_forms{
    width: 78vw;
    /* padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1em; */
    padding-left: 2em;
}
