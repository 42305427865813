.card_membership_container{
    background: #fff;
    padding: 2em;
    border-radius: 6px;
}

.title_membership_card{
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
}
.text_membership_card{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
}
.btn-membership{
    background: black;
    color: white;
    border-radius: 60px;
    border: 1px solid black;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: .3em 3.5em;
}
.beneficios {
    list-style: none;
}

.beneficios li:before {
    content: '✓';
}