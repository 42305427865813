.card_project_grid{
    background: #fff;
    padding: 1.25em;
    /* margin: 1em; */
    margin-bottom: 1em;
    border-radius: 10px;
    border: 1px solid #e0e2e4;
}
.icon_cards_brands_container{
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    transition: .5s;
}
.icon_cards_brands_container:hover{
    cursor: pointer;
    color: #3385ff;
}

.date_text_card_brand{
    margin-top: .5em;
    font-size: 12px;
    font-weight: 600;
    color: gray;
}

.title_card_text{
    font-weight: bold;
    text-transform: capitalize;
    font-size: 12px;
}
.createdby_card_brand{
    font-size: 12px;
    font-weight: 600;
    color: gray;
}
.img_card_brand{
    width: 100%;
    height: 180px;
    border-radius: 15px;
    /* box-shadow: 0 3px 20px 0 #e9edf0; */
    background-color: #fff;
    object-fit: cover;
}