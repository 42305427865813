body .download{
    background-color: #fff;
}
.downloads_banner_title{
    background: black;
    padding-top: 110px;
    padding-bottom: 70px;
    padding-right: 40px;
    padding-left: 60px;
}
.title_resource_downloads{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding-bottom: 10px;
}
.title_brand_downloads{
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.logo_image_build_container{
    padding: 5.125em 6.875em;
    background-color: #fff;
}
.logo_image_build{
    width: 310px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 3px 20px 0 #e9edf0;
    background-color: #fff;
    object-fit: contain;
}
.title-principal-logo{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
}
.info_text_download{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    margin-top: 5px;
}
.info_logo_download{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    margin-top: 5px;
}
.titles_logo{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
}
.logos_container{
    background-color: #fff;
}
.subtitle_logo{
    font-size: .9rem;
}
.download_button_logo{
    background: #ffffff;
    margin-top: 20px;
    width: 150px;
    border-radius: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    border-color: #000;
    border: 2px solid;
}
.card_logo_container{
    background: #f4f6f7;
    padding: 35px;
    border: 1px solid #d3dce2;
    border-radius: 6px;
}
.img_card_logo{
    width: 100%;
    height: 120px;
    object-fit: contain;
    border-radius: 6px;
    box-shadow: 0 3px 20px 0 #e9edf0;
    background-color: #fff;
}
.background_image_banner{
    width: 431px;
    height: 150px;
    border-radius: 6px;
    box-shadow: 0 3px 20px 0 #e9edf0;
    background-color: #fff;
    object-fit: contain;
}
.button_download_container{
    display: flex;
    justify-content: center;
}
.flyers_container{
    background-color: #fff;
    padding: 20px 20px;
}
.flyer_title{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 20px;
}
.btn_download_dark{
    padding: 12px 20px;
    background: #000;
    border: none;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  
}
.icons_download_container{
    /* width: 100%; */
    padding: 0px 20px;
    background-color: #fff;
}
.hrSection{
    background-color: #fff;
    padding: 50px 30px;
}
.icon_img{
    width: 123px;
    height: 123px;
    border-radius: 6px;
    border: solid 1px #d3dce2;
    background-color: #fff;
    object-fit: contain;
}
@media (max-width: 800px) {
    .logo_image_build_container{
        padding: 3em 4em;
    }
}

@media (max-width: 600px) {
    .login-form-style {
        padding: 1.5em 3em;
    }
}

@media (max-width: 400px) {
    .login-form-style {
        width: 1em 1em;
    }
}