@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}
p{
    margin: 0;
}
a{
    cursor: pointer;
    text-decoration: none;
}
.agency{
    width: 25%;
}
.header{
    padding-top: 20px;
    padding-bottom: 20px;
}
.btn-profile-navbar{
    margin-right: 5px;
}
.labels-login{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
}
.btn-login{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    padding-top: 14px;
    padding-bottom: 14px;
    border: none;
}
.fw-gray{
    color:#777d81;
    font-size: 16px;
    font-weight: 500;
}
.date_text_card_brand{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #777d81;
}
.title_card_text{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding-top: 10px;
}
.createdby_card_brand{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #9ba2a7;
    padding-top: 5px;
}
.option-proyect{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    padding-top: 5px;
}
.CoverPhoto{
    display: flex;
    width: 100%;
    height: 180px;
    border-radius: 15px;
    background-color: #DADADA;
    margin-bottom: 10px;
    color: #9ba2a7;
    font-size: 18px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.textCoverPhoto{
    color: #9ba2a7;
    font-size: 18px;
    align-self: center;
    justify-self: center;
    text-align: center;
}
.brand_subtheme{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
    margin-top: 30px;
}
.recomendationText{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #9ba2a7;
    margin-bottom: 15px; 
}
.addNew{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-top: 20px;
    margin-bottom: 20px;
    align-content: center;
    align-items: center;
}
.addNewElement{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    /* margin: auto auto; */
    /* align-content: center;
    align-items: center; */
}
.addNewElement_Small{
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-top: 40px;
}
.addNewElement a, a:hover{
    color: #000000;
}
.deleteOne{
    font-size: 24px;
}
.myOwn{
    margin-bottom: 0;
    margin-top: 0;
}
.sizeCircle{
    font-size: 20px;
    color:#000;
    margin-right: 5px;
}
.deleteCircle {
    color:#d3dce2;
    size: 40px;
}
.alignright{
    text-align: right;
}
.addnew_container{
    background: #e9edf0;
    border: 1px;
    border-color: #d3dce2;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    border-radius: 5px;
}
.addnew_container:hover{
    cursor: pointer;
}
.progress_bar{
    background-color: #fff;
    height: 8px;
}
input::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #9ba2a7;
}
textarea::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #9ba2a7;
}
.activeMenu{
    color: #000;
}
html {
    scroll-behavior: smooth;
}