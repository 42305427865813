.login-form-style {
    margin: auto auto;
    border-radius: 7px;
    padding: 3em 2.2em;
    background-color: #ffffff;
    width: 35vw;
}
.labels-login {
    font-size: .8rem;
    font-weight: bold;
    
}
.btn-login{
    background: #000000;
    width: 100%;
    color: #ffffff;
    padding: .6em 0;
    border-radius: 40px;
}

.remember-text{
    font-weight: 500;
    color: gray;
}

.inputs-login-page {
    border-radius: 3px;
}

@media (max-width: 800px) {
    .login-form-style {
        width: 62vw;
    }
}

@media (max-width: 600px) {
    .login-form-style {
        width: 60vw;
    }
}

@media (max-width: 400px) {
    .login-form-style {
        width: 80vw;
    }
}