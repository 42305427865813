.membership_title{
    font-weight: 500;
    font-size: 1.2rem;
    padding-left: .5em;
}
.mymembership_info_camp{
    background: #fff;
    border-radius: 8px;
    margin-top: .5em;
    padding: 1em .5em ;
}
.p_membership_info_camp{
    font-size: .9rem;
}
.free_plan{
    color: #fff;
    background: #00b672;
    border-radius: 40px;
    padding: 0 .5em;
}
.modified_membership{
    text-decoration: underline;
}
.modified_membership:hover{
    cursor: pointer;
}
.memberships_cards{
    margin-top: 2em;
}