body{
    background: #f4f6f7;
}

.img_brandcore{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.img_values{
    width: 90px;
    height: 90px;
    object-fit: contain;
    border-radius: 6px;
}

.brandstrategy_page_main{
    padding:0px;
}
.title_submain{
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 46px;
}
.values_container{
    background-image: linear-gradient(to bottom, #fff 85%, #e9edf0);
    padding: 70px 40px;
}
.left_tittleTop{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 40px; 
}
.values_tittle {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.values_description {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.brandcore_tittle{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 60px;
}
.image_section{
    padding:0px;
    object-fit: cover;
    height: auto;
}
.brancore_brandstrategy_container{
    background-image: linear-gradient(to bottom, #fff 80%, #e9edf0);
    /* padding-bottom: 2em; */
    /* background-color: #fff; */
    padding-top: 65px;
    padding-bottom: 120px;
    padding-left: 100px;
    padding-right: 100px;
}
.coverSection{
    background-color: #000000;
    padding: 55px 50px;
}
.brandcore_strategy_cover{
    font-size: 50px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.brandcore_strategy_core{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.brandcore_strategy_info{
    margin-top: 2em;
    text-align: center;
    line-height: 1.5;
}
.img_values_card{
    object-fit: contain;
    /* border-radius: 6px; */
    margin-bottom: 1em !important;
    height: 90px;
}
.mission_container{
    background: #e9edf0;
    padding: 60px 40px 100px 60px;
}
.title_mission-vision{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 40px;
}
.info_mission-vision{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.vision_container{
    background: #000000;
    padding: 4em 2em;
}
.statement_info_container{
}
.statement_info_container{
    margin-top: 4em;
}
.img_statement_container{
    padding:0px;
    max-height: 600px;
    min-height: 450px;
}
.img_statment{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.statement_container{
    background-color: #fff;
    padding: 70px 40px 30px 50px;
}
.statement_tittle{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding-bottom: 40px;
}
.statement_info{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.statement_company{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.block_text_info{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;  
}
.WhiteBlock{
    background-color: white;
    height: 65px;
}
.m30{
    margin-bottom: 30px;
}
.progress-bar{
    background: #9ba2a7;
    border-radius: 0px;
}
.progress_bar{
    border-radius: 10px;
}
.titleBar{
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 14px;
}
.porcentualBar{
    font-size: 14px;
}
.TagLineSection{
    padding: 60px 40px 60px 40px;
    margin-bottom: 50px;
}
.logo_sidebar{
    /* height: 100px; */
    margin-bottom: 100px;
    margin-top: 50px;
    width: 100%;
    object-fit: contain;
}