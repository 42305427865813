.form_brand_container{
    background: #ffffff;
    border: 1px solid #d6dbdd;
    border-radius: 6px;
    width: 50vw;
    padding: 5em 3em;
}
@media (max-width: 900px) {
    .form_brand_container {
        width: 60vw;
    }
}
@media (max-width: 800px) {
    .form_brand_container {
        width: 62vw;
    }
}
.new_brand_title{
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;

}
.input_brand_form{
    padding: .8em .5em;
    border-radius: 3px;
}
.btn_brand_form_container{
    display: flex;
    justify-content: center;
}
.btn_brand_form{
    margin: 0 1em;
    padding: .5em 1.3em;
    border-radius: 60px;
    background: white;
}

@media (max-width: 400px) {
    .form_brand_container {
        width: 90vw;
    }
}
@media (max-width: 600px) {
    .form_brand_container {
        width: 80vw;
    }
}