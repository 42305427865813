.infouser_header{
    display: flex;
    padding: 1em 0;
}
.infouser_title{
    font-weight: 700;
    margin-right: auto;
    font-size: 1.5rem;
}
.profileTitle{
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
}
.subtitle_Profile{
    font-size: 21px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
}
.saveinfobuttoncontainer{
    display: flex;
    justify-content: end;
}
.save_infouser{
    background: #000000;
    border-radius: 22px;
    color: #ffffff;
    padding: 12px 30px;
    border:none;
}
.cancel_infouser{
    background: #ffffff;
    border-radius: 22px;
    color: #000000;
    padding: 12px 30px;
    border:1px solid #000000;
}
.form_userinfo{
    /* padding: 1em 0; */
}
.formuser_container{
    background: #ffffff;
    margin-top: 1em;
    border-radius: 8px;
    padding: 2em;
}
.label_infouser{
    font-size: .8rem;
    font-weight: 700;
    margin-bottom: .5em;
}
.img_icon_container{
    display: flex;
    /* padding: 2em; */
    justify-content: center;
    align-items: center;
    border: 1px dashed #d3dce2;
    border-radius: 8px;
    height: 120px;
    object-fit: contain;
}
.img_infouser_container{
    display: flex;
    /* padding: 2em; */
    justify-content: center;
    align-items: center;
    border: 1px dashed #d3dce2;
    border-radius: 4px;
    height: 150px;
    object-fit: contain;
    
}
.placeholder_img{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #9ba2a7;
    padding-left: 15%;
    padding-right: 15%;
}
.picture_avatar_suerinfo{
    color: #DFE2E4;
    font-size: 3rem;
}
.input_description_userinfo{
    border: 1px solid #DFE2E4;
}
.img-profile-user{
    width: 100%;
    height: 100%;
    max-height: 130px;
    /* height: 30vh; */
    object-fit: contain;
}
.format_image_style{
    font-size: 12px;
    color: #9ba2a7;
}
.img-iconography{
    width: 100%;
    height: 100px;
    object-fit: contain;
}
.values_container-form{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d3dce2;
    border-radius: 4px;
    height: 100px;
    width: 100px;
    object-fit: contain;
}
.img-values-form{
    width: 90px;
    height: 90px;
    object-fit: contain;
}
.bi-check-square{
    font-size: 20px;
}
.bi-square{
    font-size: 20px;
}
.ShowText{
    color: #777d81;
    font-size: 14px;
    font-weight: 500;
}
.value-label{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.21;
}
.titleTable-Container{
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #ffffff;
    border-radius: 6px;
    text-align: center;
}
.titleTable{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
}