.brandcore_top_text{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
}
.thebrandcoretext{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    padding-top: 20px;
    padding-bottom: 15px;
}
.brandcore_info{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    padding-bottom: 15px;
}
.image_brandcore{
    width: 100%;
    max-height: 400px;
}
.bran_core_standard_container{
    background-image: linear-gradient(to bottom, #fff 80%, #e9edf0);
    padding-top: 65px;
    padding-bottom: 120px;
    padding-left: 60px;
    padding-right: 60px;
} 
.img_identity_main{
    width: 100%;
    align-self: center;
    margin: auto;
    object-fit: contain;
    padding: 1.5em;
}
.identity_container{
    background: #ffffff;
    padding-bottom: 70px;
    padding-top: 80px;
    padding-left: 60px;
    padding-right: 60px;
}
.brand_identity_title{
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
}
.brandidentity_logo img{
    /* border: 1px solid #d3dce2; */
    height: 225px;
    object-fit: contain;
    align-items: center;
    display: flex;
}
.card_colors{
    padding-right: 30%;
}
.colors_specs{
    padding-top: 25px;
}
.colors_specs p{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
}
.construction-text{
    padding-top: 50px;
    padding-bottom: 60px;
}
.url_treatment-text{
    padding-top: 10px;
    padding-bottom: 40px;
}
.construction-text p{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
.text_16{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
.text_18{
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    padding-bottom: 15px;
}
.blockColor{
    border: 1px solid #d3dce2;
    height: 150px;
    margin-top: 15px;
}
.category_color{
    padding-bottom: 30px;
    padding-top: 30px;
    border-bottom: 1px solid #d3dce2;
}
.colorname{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    padding-bottom: 10px;
}
.colorname_text{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
}
.dont_text-info{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    padding-top: 30px;
}
.url_treatment-text p{
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
.symbol-image{
    object-fit: contain;
    height: 225px;
    /* border: 1px solid #d3dce2; */
    width: 100%;
    padding: 1.5em;
}
.url_image{
    object-fit: contain;
    height: 110px;
    /* border: 1px solid #d3dce2; */
    width: 100%;
}
.photography_image{
    object-fit: contain;
    height: 140px;
    /* border: 1px solid #d3dce2; */
    width: 100%;
}
.color_count {
    border-bottom: 2px solid black;
    padding-bottom: 1em;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
}
.contianer_comun_standard{
    border-bottom: 1px solid #e9edf0;
    /* padding-bottom: 2em; */
}
.primarycolor_container{
    border: 2px solid #e9edf0;
    padding: 1.5em 1em;
    margin-bottom: 2em;
}
.primary_color_title{
    font-size: 1.1rem;
}
.color_primary_subcolor{
    font-size: .8rem;
}
.text_primary_color_name{
    border-bottom: 1px solid #e9edf0;
    padding-bottom: 2em;
}
.secondarycolor_card{
    border: 1px solid #e9edf0;
    margin-top: .5em;
    padding: 1em .5em;
}