.submenu_maincontainer{
    /* margin: 1.5em 3em; */
}
.submenu_title{
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    text-transform: capitalize;
    margin-bottom: .5em;
    margin-right: auto;
}
.title_submenu_container{
    border-bottom: 1px solid #DFE2E4;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
    padding-bottom: 20px;
    padding-top: 30px;
    background: #F4F6F7;
}
.settings_button{
    background: #fff;
    border-radius: 100px;
    border: 1px solid black;
    padding: .3em 1em;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    content: center;
    display: flex;
}
.cancel_delete_button{
    background: #fff;
    border-radius: 100px;
    border: 1px solid black;
}
.gearIcon{
    font-size: 19px;
    padding-right: 5px;
}
.me-1{
    margin-left:10px;
    font-size: 14px;
    font-weight: normal;
    align-items: center;
}
.save_exit_button{
    background: #000000;
    color: white;
    border: 0;
    border-radius: 100px;
    width: 130px;
    padding: 14px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
}
.label_input_settings{
    font-size: 21px;
    line-height: 1.19;
    margin-bottom: .5em;
    font-weight: 500;
}
.inputs_settings_container{
    border-bottom: 1px solid #DFE2E4;
    margin-bottom: 2em;
}
.input_settings{
    border-radius: 4px;
    margin-bottom: 2.219em;
    padding: 1em .5em;
}
.input_colors{
    border-radius: 4px;
    margin-bottom: 1.219em;
    padding: 1em .5em;
}
.add-more-Container{
    display:flex;
    margin-top: 10px;
    margin-bottom: 40px;
    align-items: center;
    align-content: center;
}
.add-more-Container-top{
    display:flex;
    margin-top: 10px;
    margin-bottom: 40px;
    align-items: start;
    align-content: center;
}
.add-more-Container, .add-more-Container-top a{
    background-color: #e9edf0;
    border-radius: 4px;
    border: 1px dashed #d3dce2;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}
.add-more-Container_Small{
    display:block;
    padding-top: 35px;
    margin-bottom: 40px;
}
.add-more-Container_Small a{
    background-color: #e9edf0;
    border-radius: 4px;
    border: 1px dashed #d3dce2;
    width: 100%;
    padding: 32px;
    text-align: center;
    
}
.label_input_brand_standard{
    font-size: .8rem;
}
.identity_color_text{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
}
.symbol_title{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
}
.logo_version_url{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
}
.logo_dont_aplication{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    padding-bottom: 10px;
}
.ShowText{
    color: #777d81;
    font-size: 14px;
    font-weight: 500;
}
.bi-check-square{
    font-size: 20px;
}
.bi-square{
    font-size: 20px;
}
.ShowText{
    color: #777d81;
    font-size: 14px;
    font-weight: 500;
}
.value-label{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.21;
}
.bi-exclamation-circle{
    color: #000000;
}
.swal2-popup{
    width: 38em !important;
}
