.navbar-container{
    background: #ffffff;
    display: flex;
    align-items: center;
}
.Navbar-title{
    font-weight: 700;
    font-size: 1.4rem;
    
}
.subtitles-navbar{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    color: #000000;
}
.btn-profile-navbar{
    border: 0;
    background: transparent;
}
.navbar_user_img{
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 15px;
}
.userName{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    
}
.nav-item .me-auto{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    display: flex;
    padding: .55em 0;
    text-decoration: none;
    color: #9ba2a7;
}
.nav-item .me-auto:hover{
    color: black;
}
.nav-item .active{
    color: black;
}
.nav-item:active{
    color: black;
}

ul.nav-menu{
    padding-left: 0px;
    width: 100%;
}
.brand_subtheme{
    list-style: none;
}
.navbar{
    padding-right: 0px;
}