.footer_container{
    background-color: #777d81;
    /* width: 100%; */
    /* padding: 4em; */
    padding: 4em 1em 4em 1em;
}

.text_footer{
    text-align: center;
    color: #fff;
}

.btn_canel_footer_container{
    display: flex;
    justify-content: end;
    align-items: end;
}
.btn_save_footer_container{
    display: flex;
    justify-content: start;
    align-items: end;
}

.btn_cancel_footer{
    width: 150px;
    height: 50px;
    border-radius: 30px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;

}
.btn_save_footer{
    width: 160px;
    height: 50px;
    border-radius: 30px;
    background: #fff;
    border:0px
}
.brand_center{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding-bottom: 15px;
}
.desc_brand_center{
    font-size: 19px;
    font-weight: normal;;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}