.icon-home-window{
    font-size: 8rem;
}
.home_empty_container{
    display: flex;
    flex-direction: column;
    
    align-items: center;
}
.create_guideline_home_btn{
    background: #000000;
    border: 0;
    color: #ffffff;
    padding: .75em 1em;
    border-radius: 50px;
}