.projects_grid_main_container{
    /* margin: 2em 4em; */
    background-color: #f4f6f7;
}
.projects_grid_main_Title{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .25em;
}
.underline_div{
    border-bottom: 1px solid #e0e2e4;
}
body{
    background: #f4f6f7;
}
